import "./Pages.css";


const UpdatesPage = () => {
    return (
        <div className={"subpage"}>
        <h2>Updates</h2>
        <p>Updates go here</p>
        </div>
    );
}

export default UpdatesPage;
